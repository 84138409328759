import React from 'react'
import { graphql } from 'gatsby'

export default function ThankYou({ data }) {

    const page = data.markdownRemark.frontmatter;

    return (
        <div className='page-content bg-light'>
            <div className="container">
                <div className='row'>
                    <div className='col-md-6'>
                        <div className='page-content__header mt-md-5'>
                            <h2 className="page-content__headline" dangerouslySetInnerHTML={{ __html: page.page_headline }} />
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-8'>
                        <p dangerouslySetInnerHTML={{ __html: page.page_content }} />
                        <div className='mt-5'>
                            <a href='/' className='button px-4'>Back to homepage ›</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}

export const query = graphql`
  query ThankYouPage($id: String!) {
    markdownRemark(id: {eq: $id}) {
        html
        frontmatter {
            title
            page_headline
            page_content
        }
    }
  }
`;